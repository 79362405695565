<template>
  <div class="q-pa-md flex flex-column gap-md">
    <div class="text-h6">
      {{
        gaebDocument.boq.buildingProject || $t("gaebViewer.billOfQuantities")
      }}
    </div>
    <table>
      <tr>
        <th>Position</th>
        <th align="left">Beschreibung</th>
        <th align="right">Menge</th>
        <th align="left">Einheit</th>
      </tr>
      <template
        v-for="position in gaebDocument.boq.positions"
        :key="position.referenceId"
      >
        <tr>
          <td valign="top">{{ position.referenceId }}</td>
          <td colspan="3">
            <p>
              <b>{{ position.textShort }}</b>
            </p>
            <div v-html="position.text"></div>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td align="right">{{ position.amount }}</td>
          <td>{{ $t(`productUnit.${position.originalUnit}`) }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script setup lang="ts">
import type { GaebDocument } from "@/types/gaeb";

const props = defineProps<{
  gaebDocument: GaebDocument;
}>();

console.log("gaebDocument", props.gaebDocument.boq);
</script>

<style lang="scss"></style>
